// These are for story and clip time in and time out.
jQuery.validator.addMethod('isGreaterThanTimeIn', function (value, element) {
  var timeIn = $('.time-in');
  var timeOut = $('.time-out');

  var arbitraryDate = '2017-1-1';
  var arbitraryDateWithTimeIn = new Date(arbitraryDate + ' ' + timeIn.val().replace(/\s+/g, ''));
  var arbitraryDateWithTimeOut = new Date(arbitraryDate + ' ' + timeOut.val().replace(/\s+/g, ''));
  return this.optional(element) || arbitraryDateWithTimeOut > arbitraryDateWithTimeIn;
}, 'Please enter a time out that is greater than the time in.');

jQuery.validator.addMethod('isLessThanOrEqualToParentEndTime', function (value, element) {
  var timeOut = $('.time-out');
  var parentEndTime = $('.parent-end-time');

  var arbitraryDate = '2017-1-1';
  var arbitraryDateWithTimeOut = new Date(arbitraryDate + ' ' + timeOut.val().replace(/\s+/g, ''));
  var arbitraryDateWithParentTime = new Date(arbitraryDate + ' ' + parentEndTime.val().replace(/\s+/g, ''));
  return this.optional(element) || arbitraryDateWithTimeOut <= arbitraryDateWithParentTime;
}, 'Please enter a time out that is less than or equal to the parent Reel or Story time out.');
