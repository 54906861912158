import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="items-per-page"
export default class extends Controller {
  updatePerPage(event) {
    const queryString = location.search.replace(/&per_page=\d{1,2}/, "");
    const perPage = $(event.currentTarget).val();

    window.location = `${queryString}&per_page=${perPage}`;
  }
}
