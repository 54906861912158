import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import TomSelect from "tom-select";

// Connects to data-controller="advanced-search"
export default class extends Controller {
  static targets = ["chevronIcon", "searchForm"];

  connect() {
    this.collapseEl = new Collapse(this.searchFormTarget, { toggle: false });

    new TomSelect($(".admin-search #start_year"), {
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      persist: false,
    });

    new TomSelect($(".admin-search #end_year"), {
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      persist: false,
    });

    new TomSelect($(".admin-search #source_id"), {
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      persist: false,
    });

    new TomSelect($(".admin-search #decade_shot"), {
      plugins: ["remove_button", "input_autogrow", "clear_button"],
      persist: false,
    });
  }

  disconnect() {
    this.collapseEl.dispose();
  }

  toggle() {
    this.collapseEl.toggle();
    this.chevronIconTarget.classList.toggle("bi-chevron-down");
    this.chevronIconTarget.classList.toggle("bi-chevron-up");
  }
}
