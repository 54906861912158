import { Controller } from "@hotwired/stimulus";
import { Toast } from "bootstrap";

// Connects to data-controller="toast"
export default class extends Controller {
  static targets = ["container", "body"];

  connect() {
    this.toastEl = new Toast(this.containerTarget);
  }

  disconnect() {
    this.toastEl.dispose();
  }

  show(content, options = { success: true }) {
    this._setBgClass(options.success);
    this.bodyTarget.innerHTML = content;
    this.toastEl.show();
  }

  _setBgClass(successRequest) {
    if (successRequest) {
      this.containerTarget.classList.remove("bg-danger");
      this.containerTarget.classList.add("bg-success");
    } else {
      this.containerTarget.classList.remove("bg-success");
      this.containerTarget.classList.add("bg-danger");
    }
  }
}
