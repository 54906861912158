import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Transloadit from "@uppy/transloadit";
import GoldenRetriever from "@uppy/golden-retriever";

const selectSettings = {
  plugins: ["remove_button", "input_autogrow", "clear_button"],
  persist: false,
};

// Connects to data-controller="admin--documents"
export default class extends Controller {
  static targets = ["sourcesList", "newDocumentSection", "documentUpload"];

  sourcesListTargetConnected(target) {
    new TomSelect(target, selectSettings);
  }

  newDocumentSectionTargetConnected() {
    const uppy = new Uppy({
      id: "upload-documents",
      autoProceed: false,
      allowMultipleUploadBatches: true,
      debug: false,
      restrictions: {
        allowedFileTypes: [
          ".doc",
          ".docx",
          ".msg",
          ".pdf",
          ".rtf",
          ".txt",
          ".xls",
          ".xlsx",
          "image/*",
        ],
      },
    })
      .use(Dashboard, {
        autoOpenFileEditor: false,
        disableInformer: false,
        disableLocalFiles: false,
        disableStatusBar: false,
        disableThumbnailGenerator: false,
        disabled: false,
        fileManagerSelectionType: "files",
        height: 550,
        hideCancelButton: false,
        hidePauseResumeButton: false,
        hideProgressAfterFinish: false,
        hideRetryButton: false,
        id: "upload-documents-dashboard",
        inline: true,
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        showRemoveButtonAfterComplete: false,
        showSelectedFiles: true,
        singleFileFullScreen: true,
        target: "#upload",
        width: 750,
      })
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: {
              key: this.documentUploadTarget.dataset.authKey,
            },
            template_id: this.documentUploadTarget.dataset.templateId,
            steps: {
              exported: {
                path:
                  this.documentUploadTarget.dataset.pathPrefix +
                  "/${file.name}",
              },
            },
          },
        },
      })
      .use(GoldenRetriever);
  }
}
