import { Controller } from "@hotwired/stimulus";
import "../packages/jquery";
import "dotdotdot";

// Connects to data-controller="request-screener"
export default class extends Controller {
  static targets = [
    "requestScreenerModal",
    "emailInput",
    "nameInput",
    "titleWrapper",
    "clipFullId",
    "clipTitle",
    "error",
  ];

  static values = {
    clipId: Number,
    email: String,
    name: String,
    clipTitle: String,
    fullId: String,
  };

  static outlets = ["toast"];

  openRequestScreenerModal(event) {
    event.preventDefault();
    this.emailValue = event.params.email;
    this.nameValue = event.params.name;
    this.clipIdValue = event.params.clipId;
    this.clipTitleValue = event.params.clipTitle;
    this.fullIdValue = event.params.fullId;

    $(this.emailInputTarget).val(this.emailValue);
    $(this.nameInputTarget).val(this.nameValue);
    $(this.clipTitleTarget).html(this.clipTitleValue);
    $(this.clipFullIdTarget).html(this.fullIdValue);
    $(this.titleWrapperTarget).dotdotdot();

    $(this.requestScreenerModalTarget).modal("show");
  }

  closeRequestScreenerModal() {
    $(this.titleWrapperTarget).trigger("destroy");
    $(this.requestScreenerModalTarget).modal("hide");
  }

  sendRequest() {
    const email = $(this.emailInputTarget).val();
    const name = $(this.nameInputTarget).val();

    this.hideError();

    if (name == null || name == "") {
      this.showError("Please enter your name.");
      return;
    }

    if (email == null || email == "") {
      this.showError("Please enter an email for the screener to be sent to.");
      return;
    }

    var emailRe =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
    var emailValid = emailRe.test(email);

    if (!emailValid) {
      this.showError("Please enter a valid email address.");
      return;
    }

    $.ajax({
      type: "POST",
      url: `/clips/${this.clipIdValue}/request_screener`,
      data: { email: email, name: name },
      dataType: "json",
      success: (xhr) => {
        if (xhr.success == true) {
          this.toastOutlet.show("Screener requested!");
          $(this.requestScreenerModalTarget).modal("hide");
        } else if (typeof xhr.redirect_path != "undefined") {
          self.location = xhr.redirect_path;
        } else {
          this.toastOutlet.show("Sorry, we couldn't send your request. Please try again later.", { success: false });
        }
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't send your request. Please try again later.", { success: false });
      },
    });
  }

  showError(text) {
    $(this.errorTarget).text(text).addClass("ui-state-highlight");

    setTimeout(function () {
      $(this.errorTarget).removeClass("ui-state-highlight", 1500);
    }, 500);
  }

  hideError() {
    $(this.errorTarget).text("").removeClass("ui-state-highlight");
  }
}
