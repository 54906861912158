import "./packages/jquery";
import {} from "jquery-ujs";
import _ from "underscore";
import "bootstrap";
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";
import "dotdotdot";

import "./controllers";
import "./packages/wickedpicker";
import "./src/custom_validators";

$(document).ready(function () {
  $(".truncate").dotdotdot();
  $(".clip .clip-title").dotdotdot();

  //vars for calculating run time, time in, and time out
  var timeInHour = $(".timepicker-in-hour");
  var timeInMinute = $(".timepicker-in-minute");
  var timeInSecond = $(".timepicker-in-second");
  var timeInHiddenField = $(".time-in");

  var timeOutHour = $(".timepicker-out-hour");
  var timeOutMinute = $(".timepicker-out-minute");
  var timeOutSecond = $(".timepicker-out-second");
  var timeOutHiddenField = $(".time-out");

  var runTimeDisplay = $(".run-time-display");
  var runTime = $(".run-time");

  //start code for calulate run time feature
  function timeChangeClickSetup() {
    timeInHour.on("change", calculateTimeIn);
    timeInMinute.on("change", calculateTimeIn);
    timeInSecond.on("change", calculateTimeIn);
    timeOutHour.on("change", calculateTimeOut);
    timeOutMinute.on("change", calculateTimeOut);
    timeOutSecond.on("change", calculateTimeOut);

    timeInHour.on("change", calculateRunTime);
    timeInMinute.on("change", calculateRunTime);
    timeInSecond.on("change", calculateRunTime);
    timeOutHour.on("change", calculateRunTime);
    timeOutMinute.on("change", calculateRunTime);
    timeOutSecond.on("change", calculateRunTime);
  }

  function calculateTimeIn() {
    var timeInString =
      formatTimeUnit(timeInHour.val()) +
      " : " +
      formatTimeUnit(timeInMinute.val()) +
      " : " +
      formatTimeUnit(timeInSecond.val());
    timeInHiddenField.val(timeInString);
  }

  function calculateTimeOut() {
    var timeOutString =
      formatTimeUnit(timeOutHour.val()) +
      " : " +
      formatTimeUnit(timeOutMinute.val()) +
      " : " +
      formatTimeUnit(timeOutSecond.val());
    timeOutHiddenField.val(timeOutString);
  }

  function calculateRunTime() {
    var arbitraryDate = "2017-1-1";
    var arbitraryDateWithTimeIn = new Date(
      arbitraryDate + " " + timeInHiddenField.val().replace(/\s+/g, "")
    );
    var arbitraryDateWithTimeOut = new Date(
      arbitraryDate + " " + timeOutHiddenField.val().replace(/\s+/g, "")
    );

    var totalDifference =
      arbitraryDateWithTimeOut.getTime() - arbitraryDateWithTimeIn.getTime();

    var hh = Math.floor(totalDifference / 1000 / 60 / 60);
    totalDifference -= hh * 1000 * 60 * 60;
    var mm = Math.floor(totalDifference / 1000 / 60);
    totalDifference -= mm * 1000 * 60;
    var ss = Math.floor(totalDifference / 1000);
    totalDifference -= ss * 1000;

    runTimeDisplay.val(
      formatTimeUnit(hh) +
        " : " +
        formatTimeUnit(mm) +
        " : " +
        formatTimeUnit(ss)
    );
    runTime.val(
      formatTimeUnit(hh) +
        " : " +
        formatTimeUnit(mm) +
        " : " +
        formatTimeUnit(ss)
    );
  }

  function formatTimeUnit(timeUnit) {
    var timeUnitText = "00";
    if (timeUnit > 0) {
      timeUnitText = String(timeUnit);
    }
    if (timeUnitText.length === 1) {
      timeUnitText = "0" + timeUnitText;
    }
    return timeUnitText;
  }
  //end code for calulate run time feature

  timeChangeClickSetup();
});
