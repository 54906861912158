import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="order-project-settings"
export default class extends Controller {
  static targets = [
    "projectType",
    "projectTypeWarning",
    "projectTerm",
    "projectTermWarning",
    "fileFormat",
    "fileFormatWarning",
  ];

  connect() {
    if ($(this.projectTypeTarget).val() === "") {
      $(this.projectTypeWarningTarget).css("display", "block");
    }

    if ($(this.projectTermTarget).val() === "") {
      $(this.projectTermWarningTarget).css("display", "block");
    }

    if ($(this.fileFormatTarget).val() === "") {
      $(this.fileFormatWarningTarget).css("display", "block");
    }
  }

  updateTerms() {
    $.ajax({
      url: "/update_project_terms",
      data: {
        project_type_id: $("#order_project_type_id").val(),
      },
      dataType: "json",
      success: (terms) => {
        $(this.projectTermTarget).empty();
        $(this.projectTermTarget).append("<option>Select timeframe</option>");
        $.each(terms, (_index, term) => {
          $(this.projectTermTarget).append(`<option value=${term}>${this.renderOptionText(term)}</option>`);
        });
      },
    });
  }

  renderOptionText(term) {
    switch (term) {
      case 13:
        return "13 weeks";
      case 26:
        return "26 weeks";
      case 52:
        return "1 year";
      case 520:
        return "10 years";
      case 100000:
        return "Perpetuity";
      default:
        return "0 years";
    }
  }
}
