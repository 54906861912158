import { Controller } from "@hotwired/stimulus";
import "dotdotdot";

// Connects to data-controller="clip-details"
export default class extends Controller {
  static targets = [
    "expandedDetails",
    "toggleLink",
    "toggleLinkIcon",
    "clipTitle",
    "clipDescription",
    "clipNotesSection",
  ];
  static values = {
    expanded: false,
  };

  toggleDetails(event) {
    event.preventDefault();

    this.expandedDetailsTargets.forEach((el) => {
      el.classList.toggle("d-flex");
      el.classList.toggle("d-none");
    });

    this.expandedValue = !this.expandedValue;
    this.toggleLinkIconTarget.classList.toggle("bi-chevron-down");
    this.toggleLinkIconTarget.classList.toggle("bi-chevron-up");

    if (this.expandedValue) {
      this.toggleLinkTarget.innerText = "Minimize details";
      this.extendTruncatedText();
    } else {
      this.toggleLinkTarget.innerText = "Expand details";
      this.truncateText();
    }
  }

  extendTruncatedText() {
    $(this.clipTitleTarget).trigger("destroy");
    $(this.clipTitleTarget).css("height", "auto");

    $(this.clipDescriptionTarget).trigger("destroy");
    $(this.clipDescriptionTarget).css("height", "auto");

    if (this.hasClipNotesSectionTarget) {
      $(this.clipNotesSectionTarget).trigger("destroy");
      $(this.clipNotesSectionTarget).css("height", "auto");
    }
  }

  truncateText() {
    $(this.clipTitleTarget).css("height", "50px");
    $(this.clipTitleTarget).dotdotdot();

    $(this.clipDescriptionTarget).css("height", "100px");
    $(this.clipDescriptionTarget).dotdotdot();

    if (this.hasClipNotesSectionTarget) {
      $(this.clipNotesSectionTarget).css("height", "100px");
      $(this.clipNotesSectionTarget).dotdotdot();
    }
  }
}
