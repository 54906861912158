import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="new-clipbin"
export default class extends Controller {
  static targets = ["newClipbinModal", "newClipbinNameInput", "newClipbinNoteInput", "clipbinError"];

  openNewClipbinModal(event) {
    event.preventDefault();
    this.clearNewClipbinInputs();
    $(this.newClipbinModalTarget).modal("show");
  }

  clearNewClipbinInputs() {
    $(this.newClipbinNameInputTarget).val("");
    $(this.newClipbinNoteInputTarget).val("");
  }

  closeNewClipbinModal() {
    this.clearNewClipbinInputs();
    this.clearError();
    $(this.newClipbinModalTarget).modal("hide");
  }

  saveNewClipbin() {
    if (this.checkBlankName()) {
      this.createNewClipBin(
        $(this.newClipbinNameInputTarget).val(),
        $(this.newClipbinNoteInputTarget).val(),
        (xhr, _textStatus) => {
          this.clipbinCreated(xhr);
          this.clearError();
          $(this.newClipbinModalTarget).modal("hide");
        },
        (_xhr, _textStatus, errorThrown) => {
          this.showError(errorThrown);
        }
      );
    }
  }

  showError(text) {
    $(this.clipbinErrorTarget).text(text).addClass("alert alert-warning");
  }

  clearError() {
    $(this.clipbinErrorTarget).text("").removeClass("alert alert-warning");
    $(this.newClipbinNameInputTarget).removeClass("is-invalid");
  }

  checkBlankName() {
    this.clearError();

    if ($(this.newClipbinNameInputTarget).val().length <= 0) {
      $(this.newClipbinNameInputTarget).addClass("is-invalid");
      this.showError("Name can't be blank");
      return false;
    }

    return true;
  }

  createNewClipBin(name, notes, success, error) {
    $.ajax({
      type: "POST",
      url: "/clipbins",
      data: { name: name, notes: notes },
      dataType: "json",
      success: success,
      error: error,
    });
  }

  clipbinCreated(xhr) {
    window.location = `/clipbins/${xhr.id}`;
  }
}
