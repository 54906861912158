import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

// Connects to data-controller="admin--clipbins"
export default class extends Controller {
  static targets = ["userInput"];

  userInputTargetConnected(target) {
    const userField = new TomSelect(target, {
      valueField: "id",
      labelField: "label",
      searchField: "label",
      maxItems: 1,
      closeAfterSelect: true,
      load: (term, callback) => {
        fetch(`/admin/users/autocomplete?term=${term}`)
          .then((response) => response.json())
          .then((json) => {
            callback(json);
          })
          .catch(() => {
            callback();
          });
      },
    });

    const { userId, userLabel } = target.dataset;

    if (userId && userLabel) {
      userField.addOption({ id: userId, label: userLabel });
      userField.setValue([userId]);
    }
  }
}
