import { Controller } from "@hotwired/stimulus";
import moment from "moment";

// Connects to data-controller="order-clip-duration"
export default class extends Controller {
  static targets = [
    "timepicker",
    "timeStartInput",
    "timeEndInput",
    "timeStartHidden",
    "timeEndHidden",
    "errorMessage",
    "submitButton",
  ];

  connect() {
    this.timepickerTargets.forEach((timepicker) => {
      $(timepicker).wickedpicker({
        now: $(timepicker).data("time"),
        twentyFour: true,
        title: $(timepicker).data("title"),
        showSeconds: true,
        secondsInterval: 1,
        minutesInterval: 1,
        beforeShow: null,
        show: null,
        clearable: false,
        change: this.changeDuration,
      });

      $(timepicker).on("change", this.checkDuration.bind(this));
    });
  }

  checkDuration() {
    let err = "";

    const editedTimeStart = $(this.timeStartInputTarget)
      .wickedpicker("time")
      .split(":")
      .map((piece) => piece.trim())
      .join(":");

    const editedTimeEnd = $(this.timeEndInputTarget)
      .wickedpicker("time")
      .split(":")
      .map((piece) => piece.trim())
      .join(":");

    const clipTimeStart = $(this.timeStartHiddenTarget).val();
    const clipTimeEnd = $(this.timeEndHiddenTarget).val();

    const defaultStartTime = moment(clipTimeStart, "HH:mm:SS");
    const defaultEndTime = moment(clipTimeEnd, "HH:mm:SS");
    const newStartTime = moment(editedTimeStart, "HH:mm:SS");
    const newEndTime = moment(editedTimeEnd, "HH:mm:SS");

    if (newStartTime.isBefore(defaultStartTime)) {
      err = "Error: Your chosen Start Time of " + editedTimeStart + " is before default Start Time of " + clipTimeStart;
    } else if (newStartTime.isAfter(defaultEndTime)) {
      err = "Error: Your chosen Start Time of " + editedTimeStart + " is after default End Time of " + clipTimeEnd;
    } else if (newEndTime.isBefore(defaultStartTime)) {
      err = "Error: Your chosen End Time of " + editedTimeEnd + " is before default Start Time of  " + clipTimeStart;
    } else if (newEndTime.isAfter(defaultEndTime)) {
      err = "Error: Your chosen End Time of " + editedTimeEnd + " is after default End Time of " + clipTimeEnd;
    } else if (newStartTime.isAfter(newEndTime)) {
      err = "Error: Your chosen Start Time of " + editedTimeStart + " is after chosen End Time of " + editedTimeEnd;
    } else {
      err = "";
    }

    if (err !== "") {
      $(this.errorMessageTarget).text(err);
      $(this.errorMessageTarget).removeClass("d-none");
      $(this.submitButtonTarget).prop("disabled", true);
    } else {
      $(this.errorMessageTarget).addClass("d-none");
      $(this.errorMessageTarget).text("");
      $(this.submitButtonTarget).prop("disabled", false);
    }
  }
}
