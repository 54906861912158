import { Controller } from "@hotwired/stimulus";
import _ from "underscore";

// Connects to data-controller="add-to-submission-batch"
export default class extends Controller {
  static targets = [
    "addToSubmissionModal",
    "clipTitleWrapper",
    "clipTitle",
    "clipFullId",
    "submissionBatchSelect",
    "clipError",
    "clipNotesInput",
    "submissionBatchNotesInput",
    "submissionBatchSelectArea",
    "submissionBatchCreateArea",
    "newSubmissionBatchInput",
    "addToSubmissionBatchButton",
  ];

  static values = {
    clipId: Number,
    clipTitle: String,
    clipFullId: String,
    createNewSubmissionBatch: Boolean,
  };

  static outlets = ["toast"];

  initialize() {
    _.templateSettings = {
      interpolate: /\{\{\=(.+?)\}\}/g,
      evaluate: /\{\{(.+?)\}\}/g,
    };
  }

  openAddToSubmissionModal(event) {
    event.preventDefault();

    this.clipIdValue = event.params.clipId;
    this.clipTitleValue = event.params.clipTitle;
    this.clipFullIdValue = event.params.clipFullId;

    this.hideError();

    $(this.clipTitleTarget).html(this.clipTitleValue);
    $(this.clipFullIdTarget).html(this.clipFullIdValue);
    $(this.clipTitleWrapperTarget).dotdotdot();

    $.ajax({
      type: "GET",
      url: "/submission_batches",
      cache: false,
      dataType: "json",
      success: (xhr) => {
        $(xhr).each((_num, submissionBatch) => {
          $(this.submissionBatchSelectTarget).append(this.submissionBatchOptionTemplate(submissionBatch));
        });
      },
    });

    $(this.addToSubmissionModalTarget).modal("show");
  }

  closeAddToSubmissionModal() {
    this.showSelectSubmissionBatchFields();
    $(this.submissionBatchSelectTarget).empty();
    $(this.clipNotesInputTarget).val("");
    $(this.submissionBatchNotesInput).val("");
    $(this.clipTitleWrapperTarget).trigger("destroy");
    $(this.addToSubmissionModalTarget).modal("hide");
  }

  saveClipSubmissionBatch() {
    if (this.createNewSubmissionBatchValue) {
      this.createNewSubmissionBatch();
    } else {
      this.addClipToSubmissionBatch();
    }
  }

  createNewSubmissionBatch() {
    const submissionBatchName = $(this.newSubmissionBatchInputTarget).val();
    const submissionBatchNotes = $(this.submissionBatchNotesInputTarget).val();

    if (this.checkBlankName(submissionBatchName)) {
      $.ajax({
        type: "POST",
        url: "/submission_batches",
        data: { name: submissionBatchName, notes: submissionBatchNotes },
        dataType: "json",
        success: (submissionBatch) => {
          $(this.submissionBatchSelectTarget).append(this.submissionBatchOptionTemplate(submissionBatch));
          $(this.submissionBatchSelectTarget).val(submissionBatch.id);
          this.toastOutlet.show(`Created new submission batch: ${submissionBatch.name}`);
          this.showSelectSubmissionBatchFields();
        },
      });
    }
  }

  addClipToSubmissionBatch() {
    const submissionBatchId = $(this.submissionBatchSelectTarget).val();
    const notes = $(this.clipNotesInputTarget).val();

    $.ajax({
      type: "POST",
      url: "/submission_batches/add_clip",
      data: { clip_id: this.clipIdValue, id: submissionBatchId, notes: notes },
      dataType: "json",
      success: () => {
        this.toastOutlet.show("Clip added to submission batch");
        this.closeAddToSubmissionModal();
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't add the clip to the submission batch. Please try again.", {
          success: false,
        });
      },
    });
  }

  showCreateSubmissionBatchFields() {
    this.hideError();
    this.createNewSubmissionBatchValue = true;

    $(this.submissionBatchSelectAreaTarget).hide();
    $(this.submissionBatchCreateAreaTarget).show();
    $(this.newSubmissionBatchInputTarget).val("");
    $(this.clipNotesInputTarget).val("");
    $(this.newSubmissionBatchInputTarget).trigger("focus");
    $(this.addToSubmissionBatchButtonTarget).text("Save new Submission Batch");
  }

  showSelectSubmissionBatchFields() {
    this.createNewSubmissionBatchValue = false;

    $(this.submissionBatchCreateAreaTarget).hide();
    $(this.submissionBatchSelectAreaTarget).show();
    $(this.newSubmissionBatchInputTarget).val("");
    $(this.submissionBatchNotesInputTarget).val("");
    $(this.addToSubmissionBatchButtonTarget).text("Add to Submission Batch");
  }

  showError(text) {
    $(this.clipErrorTarget).text(text).addClass("ui-state-highlight");

    setTimeout(function () {
      $(this.clipErrorTarget).removeClass("ui-state-highlight", 1500);
    }, 500);
  }

  hideError() {
    $(this.newSubmissionBatchInputTarget).removeClass("ui-state-error");
    $(this.clipErrorTarget).text("").removeClass("ui-state-highlight");
  }

  submissionBatchOptionTemplate(submissionBatch) {
    const template = _.template("<option value='{{= id }}'>Batch #{{= id }}: {{= name }}</option>");
    return template(submissionBatch);
  }

  checkBlankName(name) {
    this.hideError();

    if (name.length <= 0) {
      $(this.newSubmissionBatchInputTarget).addClass("ui-state-error");
      this.showError("Name can't be blank");
      return false;
    }

    return true;
  }
}
