import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clipbin-header"
export default class extends Controller {
  static targets = [
    "editClipbinModal",
    "editClipbinNameInput",
    "editClipbinNoteInput",
    "clipbinError",
    "clipbinHeaderName",
    "clipbinNotesArea",
    "clipbinNote",
    "sendBinToWPAModal",
    "sendBinToWPATitle",
    "sendBinToWPAEmailInput",
    "sendBinToWPACommentsInput",
    "shareBinModal",
    "shareBinTitle",
    "shareBinEmailInput",
    "shareBinCommentsInput",
  ];

  static values = {
    clipbinId: Number,
    clipbinName: String,
    clipbinNote: String,
  };

  static outlets = ["toast"];

  //////////////////////////////
  // Edit Clipbin functionality
  //////////////////////////////
  editClipbin(event) {
    event.preventDefault();
    $(this.editClipbinNameInputTarget).val(this.clipbinNameValue.trim());
    $(this.editClipbinNoteInputTarget).val(this.clipbinNoteValue.trim());
    $(this.editClipbinModalTarget).modal("show");
  }

  closeEditClipbinModal() {
    this.clearEditClipbinInputs();
    this.clearError();
    $(this.editClipbinModalTarget).modal("hide");
  }

  clearEditClipbinInputs() {
    $(this.editClipbinNameInputTarget).val("");
    $(this.editClipbinNoteInputTarget).val("");
  }

  updateClipbin() {
    const modal = $(this.editClipbinModalTarget);
    if (this.checkBlankName()) {
      this.updateClipBin();
    }
  }

  updateClipBin() {
    const name = $(this.editClipbinNameInputTarget).val();
    const notes = $(this.editClipbinNoteInputTarget).val();

    $.ajax({
      type: "PUT",
      url: `/clipbins/${this.clipbinIdValue}`,
      data: { name, notes },
      dataType: "json",
      success: (xhr, _textStatus) => {
        window.location = `/clipbins/${xhr.id}`;
      },
      error: (_xhr, _textStatus, errorThrown) => {
        this.showError(errorThrown);
      },
    });
  }

  updateClipNameInList(clipbin) {
    $("#bin-list-" + clipbin.id + " a").text(clipbin.name + " (" + clipbin.clip_count + ")");
  }

  updateClipbinHeader(clipbin) {
    $(this.clipbinHeaderNameTarget).text(clipbin.name);
    $(this.clipbinNoteTarget).text(clipbin.notes);

    if (clipbin.notes && clipbin.notes.length > 0) {
      $(this.clipbinNotesAreaTarget).removeClass("hidden");
    } else {
      console.log($(this.clipbinNotesAreaTarget));
      $(this.clipbinNotesAreaTarget).addClass("hidden");
    }
  }

  checkBlankName() {
    this.clearError();

    if ($(this.editClipbinNameInputTarget).val().length <= 0) {
      $(this.editClipbinNameInputTarget).addClass("ui-state-error");
      this.showError("Name can't be blank");
      return false;
    }

    return true;
  }

  showError(text) {
    $(this.clipbinErrorTarget).text(text).addClass("ui-state-highlight");

    setTimeout(function () {
      $(this.clipbinErrorTarget).removeClass("ui-state-highlight", 1500);
    }, 500);
  }

  clearError() {
    $(this.clipbinErrorTarget).text("").removeClass("ui-state-highlight");
    $(this.editClipbinNameInputTarget).removeClass("ui-state-error");
  }

  sortClipbinList() {
    $(".clipbin-set").html(
      $(".clipbin-container", ".clipbin-set").sort((a, b) => {
        const aFullName = $(a).find("a").text().trim(),
          aName = aFullName.substr(0, aFullName.length - 4).toLowerCase(),
          bFullName = $(b).find("a").text().trim(),
          bName = bFullName.substr(0, bFullName.length - 4).toLowerCase();
        return aName == bName ? 0 : aName < bName ? -1 : 1;
      })
    );
  }

  /////////////////////////////////
  // Send Bin to WPA functionality
  /////////////////////////////////
  sendBinToWPA(event) {
    event.preventDefault();
    $(this.sendBinToWPATitleTarget).text(`Email ${this.clipbinNameValue} to WPA`);
    $(this.sendBinToWPAModalTarget).modal("show");
  }

  closeSendBinToWPAModal() {
    $(this.sendBinToWPATitleTarget).text("");
    $(this.sendBinToWPACommentsInputTarget).val("");
    $(this.sendBinToWPAModalTarget).modal("hide");
  }

  emailBinToWPA() {
    const emailTo = $(this.sendBinToWPAEmailInputTarget).val();
    const comments = $(this.sendBinToWPACommentsInputTarget).val();

    $.ajax({
      type: "POST",
      url: `/clipbins/${this.clipbinIdValue}/send_to_wpa`,
      data: { comments: comments, email_to: emailTo },
      dataType: "json",
      success: () => {
        this.toastOutlet.show("Clipbin sent to WPA.");
        this.closeSendBinToWPAModal();
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't send your request. Please try again later.", { success: false });
      },
    });
  }

  ///////////////////////////
  // Share Bin functionality
  ///////////////////////////

  shareClipbin(event) {
    event.preventDefault();
    $(this.shareBinTitleTarget).text(`Email ${this.clipbinNameValue} to Friend`);
    $(this.shareBinModalTarget).modal("show");
  }

  closeShareBinModal() {
    $(this.shareBinTitleTarget).text("");
    $(this.shareBinEmailInputTarget).val("");
    $(this.shareBinCommentsInputTarget).val("");
    $(this.shareBinModalTarget).modal("hide");
  }

  shareClipbinSend() {
    const emailTo = $(this.shareBinEmailInputTarget).val();
    const comments = $(this.shareBinCommentsInputTarget).val();

    if (this.isValidEmailAddress(emailTo)) {
      $.ajax({
        type: "POST",
        url: `/clipbins/${this.clipbinIdValue}/share`,
        data: { comments: comments, email_to: emailTo },
        dataType: "json",
        success: () => {
          this.toastOutlet.show("Clipbin shared.");
          this.closeShareBinModal();
        },
        error: () => {
          this.toastOutlet.show("Sorry, we couldn't send your request. Please try again later.", { success: false });
        },
      });
    } else {
      this.toastOutlet.show(
        "Invalid email(s). Please make sure to separate multiple emails with commas (example: friend1@gmail.com, friend2@outlook.com)",
        {
          success: false,
        }
      );
    }
  }

  isValidEmailAddress(emailAddresses) {
    var pattern = new RegExp(
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );

    return emailAddresses.split(",").every(function (email) {
      return pattern.test(email.trim());
    });
  }
}
