import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="textarea-count"
export default class extends Controller {
  static targets = ["textarea", "counter"];

  connect() {
    this._updateCounter();
  }

  count() {
    this._updateCounter();
  }

  _updateCounter() {
    this.counterTarget.innerHTML = `Char Count: ${this.textareaTarget.value.length}`;
  }
}
