import { Controller } from "@hotwired/stimulus";
import _ from "underscore";

// Connects to data-controller="add-to-clipbin"
export default class extends Controller {
  static targets = [
    "addToClipbinModal",
    "modalTitleWrapper",
    "modalTitle",
    "modalFullId",
    "clipError",
    "clipbinSelect",
    "clipbinSelectArea",
    "clipbinCreateArea",
    "newClipbinInput",
    "addToClipbinButton",
    "clipNotesInput",
    "clipbinNotesInput",
  ];

  static values = {
    clipId: Number,
    fullId: String,
    clipTitle: String,
    createNewClipbin: Boolean,
  };

  static outlets = ["toast"];

  initialize() {
    _.templateSettings = {
      interpolate: /\{\{\=(.+?)\}\}/g,
      evaluate: /\{\{(.+?)\}\}/g,
    };
  }

  openAddToClipbinModal(event) {
    event.preventDefault();

    this.clipIdValue = event.params.clipId;
    this.fullIdValue = event.params.fullId;
    this.clipTitleValue = event.params.clipTitle;

    this.hideError();
    this.setClipbinOptions();

    $(this.modalFullIdTarget).text(this.fullIdValue);
    $(this.modalTitleTarget).text(this.clipTitleValue);
    $(this.addToClipbinModalTarget).modal("show");
  }

  setClipbinOptions() {
    $.ajax({
      type: "GET",
      url: "/clipbins.json",
      cache: false,
      dataType: "json",
      data: { include_cart: false },
      success: (xhr, _textStatus) => {
        $(xhr).each((_num, clipbin) => {
          const template = _.template("<option cart='{{= cart }}' value='{{= id }}'>{{= name }}</option>");

          $(this.clipbinSelectTarget).append(template(clipbin));
        });

        $.ajax({
          type: "GET",
          url: "/clipbins/latest.json",
          dataType: "json",
          success: (clipbin) => {
            if (clipbin) {
              $(this.clipbinSelectTarget).val(clipbin.id);
            }
          },
        });
      },
    });
  }

  closeAddToClipbinModal() {
    this.showSelectClipbinFields();
    $(this.modalFullIdTarget).text("");
    $(this.modalTitleTarget).text("");
    $(this.clipNotesInputTarget).val("");
    $(this.clipbinNotesInputTarget).val("");
    $(this.clipbinSelectTarget).empty();
    $(this.addToClipbinModalTarget).modal("hide");
  }

  addClipToClipbin() {
    if (this.createNewClipbinValue) {
      this.createNewClipbin();
    } else {
      this.addToExistingClipbin();
    }
  }

  createNewClipbin() {
    const clipbinName = $(this.newClipbinInputTarget).val();
    const clipbinNotes = $(this.clipbinNotesInputTarget).val();

    if (this.checkBlankName()) {
      $.ajax({
        type: "POST",
        url: "/clipbins",
        data: { name: clipbinName, notes: clipbinNotes },
        dataType: "json",
        success: (clipbin) => {
          const template = _.template("<option cart='{{= cart }}' value='{{= id }}'>{{= name }}</option>");

          $(this.clipbinSelectTarget).append(template(clipbin));
          $(this.clipbinSelectTarget).html(
            $("option", $(this.clipbinSelectTarget)).sort((a, b) => {
              const aName = $(a).text().toLowerCase(),
                bName = $(b).text().toLowerCase(),
                aCart = $(a).attr("cart"),
                bCart = $(b).attr("cart");
              if (aCart === "true" || aCart === true) return -1;
              else if (bCart === "true" || bCart === true) return 1;
              return aName == bName ? 0 : aName < bName ? -1 : 1;
            })
          );
          $(this.clipbinSelectTarget).val(clipbin.id);
          this.toastOutlet.show(`Created new clipbin: ${clipbin.name}`);
          this.showSelectClipbinFields();
        },
      });
    }
  }

  addToExistingClipbin() {
    const notes = $(this.clipNotesInputTarget).val();
    const clipbinId = $(this.clipbinSelectTarget).val();

    if (clipbinId == "" || clipbinId == null) {
      this.showError("Please select a Clip Bin to add this Clip to.");
      return;
    }

    $.ajax({
      type: "POST",
      url: "/clipbins/add_clip",
      data: { clip_id: this.clipIdValue, id: clipbinId, notes: notes },
      dataType: "json",
      success: (clipbin) => {
        this.toastOutlet.show(`Added to clipbin: ${clipbin.name}`);
        this.closeAddToClipbinModal();
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't add the clip to the clipbin. Please try again later.", {
          success: false,
        });
      },
    });
  }

  showCreateClipbinFields() {
    this.hideError();
    this.createNewClipbinValue = true;

    $(this.clipbinSelectAreaTarget).hide();
    $(this.clipbinCreateAreaTarget).show();
    $(this.newClipbinInputTarget).val("");
    $(this.newClipbinInputTarget).trigger("focus");
    $(this.addToClipbinButtonTarget).text("Save new Clip Bin");
  }

  showSelectClipbinFields() {
    this.createNewClipbinValue = false;

    $(this.clipbinCreateAreaTarget).hide();
    $(this.clipbinSelectAreaTarget).show();
    $(this.newClipbinInputTarget).val("");
    $(this.clipbinNotesInputTarget).val("");
    $(this.addToClipbinButtonTarget).text("Add to Clip Bin");
  }

  showError(text) {
    $(this.clipErrorTarget).text(text).addClass("ui-state-highlight");

    setTimeout(function () {
      $(this.clipErrorTarget).removeClass("ui-state-highlight", 1500);
    }, 500);
  }

  hideError() {
    $(this.clipErrorTarget).text("").removeClass("ui-state-highlight");
  }

  checkBlankName() {
    this.hideError();
    $(this.newClipbinInputTarget).removeClass("ui-state-error");

    if ($(this.newClipbinInputTarget).val().length <= 0) {
      $(this.newClipbinInputTarget).addClass("ui-state-error");
      showError("Name can't be blank");
      return false;
    }

    return true;
  }
}
