import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clip-video-player"
export default class extends Controller {
  static targets = ["overlay", "videoPlayer"];

  playVideo() {
    this.overlayTarget.remove();
    this.videoPlayerTarget.setAttribute("controls", "");
    this.videoPlayerTarget.play();
  }
}
