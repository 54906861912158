import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="track-clip"
export default class extends Controller {
  static outlets = ["toast"];

  trackClip(event) {
    event.preventDefault();

    $.ajax({
      type: "POST",
      url: "/clip_trackers/create",
      data: { clip_id: event.params.clipId, user_id: event.params.userId },
      dataType: "json",
      success: (_xhr, status) => {
        if (status == "success") {
          this.toastOutlet.show("Added to clip tracker");
        } else {
          this.toastOutlet.show("Sorry, we couldn't add to the clip tracker. Please try again.", { success: false });
        }
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't add to the clip track. Please try again.", { success: false });
      },
    });
  }
}
