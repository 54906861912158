import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

const selectSettings = {
  plugins: ["remove_button", "input_autogrow", "clear_button"],
  persist: false,
  onItemAdd: function () {
    this.setTextboxValue();
    this.refreshOptions();
  },
};

const createSettings = {
  create: true,
  createFilter: function (input) {
    input = input.toLowerCase();
    return !Object.keys(this.options).some(
      (option) => option.toLowerCase() === input
    );
  },
};

// Connects to data-controller="admin--clips"
export default class extends Controller {
  static targets = [
    "assigneesList",
    "assignMyselfButton",
    "unassignMyselfButton",
    "iptcSubjectsList",
    "personalitiesList",
    "termsList",
  ];

  connect() {
    this._assigneeButtonsSetup();
  }

  assigneesListTargetConnected(target) {
    new TomSelect(target, selectSettings);
  }

  iptcSubjectsListTargetConnected(target) {
    new TomSelect(target, selectSettings);
  }

  personalitiesListTargetConnected(target) {
    new TomSelect(target, Object.assign(selectSettings, createSettings));
  }

  termsListTargetConnected(target) {
    new TomSelect(
      target,
      Object.assign(selectSettings, createSettings, {
        valueField: "name",
        labelField: "name",
        searchField: "name",
        load: (term, callback) => {
          fetch(`/admin/terms/autocomplete?term=${term}`)
            .then((response) => response.json())
            .then((json) => {
              callback(json);
            })
            .catch(() => {
              callback();
            });
        },
      })
    );
  }

  assignMyself(event) {
    event.preventDefault();

    this.assigneesListTarget.tomselect.setValue(
      this.assignMyselfButtonTarget.dataset.currentUserId
    );
    this.unassignMyselfButtonTarget.classList.remove("d-none");
    this.assignMyselfButtonTarget.classList.add("d-none");
  }

  unassignMyself(event) {
    event.preventDefault();

    this.assigneesListTarget.tomselect.clear();
    this.assignMyselfButtonTarget.classList.remove("d-none");
    this.unassignMyselfButtonTarget.classList.add("d-none");
  }

  _assigneeButtonsSetup() {
    if (
      this.assigneesListTarget.value ===
      this.assignMyselfButtonTarget.dataset.currentUserId
    ) {
      this.unassignMyselfButtonTarget.classList.remove("d-none");
      this.assignMyselfButtonTarget.classList.add("d-none");
    }
  }
}
