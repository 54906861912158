import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import Transloadit from "@uppy/transloadit";
import GoldenRetriever from "@uppy/golden-retriever";

// Connects to data-controller="admin--uploads"
export default class extends Controller {
  static targets = ["uploadSection", "upload"];

  uploadSectionTargetConnected() {
    const uppy = new Uppy({
      id: "upload",
      autoProceed: false,
      allowMultipleUploadBatches: true,
      debug: false,
      restrictions: {
        allowedFileTypes: [".mov"],
      },
      onBeforeFileAdded: (currentFile, _files) => {
        if (!this._validFileName(currentFile.name)) {
          uppy.info(
            "Invalid file name format. Please use the correct format for your asset."
          );
          return false;
        }
      },
    })
      .use(Dashboard, {
        autoOpenFileEditor: false,
        disableInformer: false,
        disableLocalFiles: false,
        disableStatusBar: false,
        disableThumbnailGenerator: false,
        disabled: false,
        fileManagerSelectionType: "files",
        height: 550,
        hideCancelButton: false,
        hidePauseResumeButton: false,
        hideProgressAfterFinish: false,
        hideRetryButton: false,
        id: "upload-dashboard",
        inline: true,
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        showRemoveButtonAfterComplete: false,
        showSelectedFiles: true,
        singleFileFullScreen: true,
        target: "#upload",
        width: 750,
      })
      .use(Transloadit, {
        assemblyOptions: {
          params: {
            auth: {
              key: this.uploadTarget.dataset.authKey,
            },
            template_id: this.uploadTarget.dataset.templateId,
            steps: {
              exported: {
                bucket: this.uploadTarget.dataset.bucket,
              },
            },
          },
        },
      })
      .use(GoldenRetriever);

    // Trigger an audit of only story assets if uploading stories to S3.
    uppy.on("complete", (_result) => {
      if (this.uploadTarget.dataset.uploadType === "stories") {
        fetch("/admin/story_assets/audit", {
          method: "POST",
          credentials: "same-origin",
        }).catch((error) => {
          console.error("Error:", error);
        });
      }
    });
  }

  _validFileName(fileName) {
    switch (this.uploadTarget.dataset.uploadType) {
      case "stories":
        return /^\d+_\d+\.mov$/.test(fileName);
      case "clips":
        return /^\d+_\d+_\d+_wm\.mov$/.test(fileName);
      case "getty-clips":
        return /^\d+_\d+_\d+\.mov$/.test(fileName);
      default:
        return false;
    }
  }
}
