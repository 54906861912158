import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="remove-from-clipbin"
export default class extends Controller {
  static targets = ["removeFromClipbinModal", "clipbinTitle", "clipCard"];

  static values = {
    clipbinId: Number,
    clipId: Number,
  };

  static outlets = ["toast"];

  openRemoveFromClipbinModal(event) {
    event.preventDefault();
    this.clipbinIdValue = event.params.clipbinId;
    this.clipIdValue = event.params.clipId;

    $(this.clipbinTitleTarget).text(event.params.clipbinTitle);
    $(this.removeFromClipbinModalTarget).modal("show");
  }

  closeRemoveFromClipbinModal() {
    $(this.removeFromClipbinModalTarget).modal("hide");
  }

  removeFromClipbin() {
    $.ajax({
      type: "DELETE",
      url: "/clipbins/remove_clip",
      data: { clip_id: this.clipIdValue, id: this.clipbinIdValue },
      dataType: "json",
      success: () => {
        $(this.clipCardTarget).remove();
        this.closeRemoveFromClipbinModal();
        this.toastOutlet.show("Clip removed from clip bin");
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't remove the clip. Please try again.", { success: false });
      },
    });
  }
}
