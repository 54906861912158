import { Controller } from "@hotwired/stimulus";
import "../packages/jquery";
import "dotdotdot";

// Connects to data-controller="edit-clip-notes"
export default class extends Controller {
  static targets = ["editNotesModal", "editNotesInput", "clipNotesFull", "clipNotesText", "clipNotesSection"];

  static values = {
    clipbinId: Number,
    clipId: Number,
  };

  openEditNotesModal(event) {
    event.preventDefault();
    this.clipbinIdValue = event.params.clipbinId;
    this.clipIdValue = event.params.clipId;

    $(this.editNotesInputTarget).val(this.clipNotesFullTarget.value);
    $(this.editNotesModalTarget).modal("show");
  }

  closeEditNotesModal() {
    $(this.editNotesInputTarget).val("");
    $(this.editNotesModalTarget).modal("hide");
  }

  updateClipNotes() {
    const notes = $(this.editNotesInputTarget).val();

    $.ajax({
      type: "PUT",
      url: `/clipbins/${this.clipbinIdValue}/clips/${this.clipIdValue}`,
      data: {
        notes: notes,
      },
      dataType: "json",
      success: (xhr) => {
        this.updateClipCardNotes(xhr.notes);
        this.closeEditNotesModal();
      },
      error: () => {
        alert("Something went wrong, please try again.");
      },
    });
  }

  updateClipCardNotes(notes) {
    $(this.clipNotesTextTarget).empty().text(notes).dotdotdot();
    $(this.clipNotesFullTarget).val(notes);

    if (notes) {
      $(this.clipNotesSectionTarget).removeClass("hidden");
    } else {
      $(this.clipNotesSectionTarget).addClass("hidden");
    }
  }
}
