import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

const selectSettings = {
  plugins: ["remove_button", "input_autogrow", "clear_button"],
  onItemAdd: function () {
    this.setTextboxValue();
    this.refreshOptions();
  },
};

// Connects to data-controller="admin--reels"
export default class extends Controller {
  static targets = ["iptcSubjectsList", "personalitiesList", "termsList"];

  iptcSubjectsListTargetConnected(target) {
    new TomSelect(target, selectSettings);
  }

  personalitiesListTargetConnected(target) {
    new TomSelect(target, selectSettings);
  }

  termsListTargetConnected(target) {
    new TomSelect(
      target,
      Object.assign(selectSettings, {
        valueField: "id",
        labelField: "name",
        searchField: "name",
        load: (term, callback) => {
          fetch(`/admin/terms/autocomplete?term=${term}`)
            .then((response) => response.json())
            .then((json) => {
              callback(json);
            })
            .catch(() => {
              callback();
            });
        },
      })
    );
  }
}
