import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="track-story"
export default class extends Controller {
  static outlets = ["toast"];

  trackStory(event) {
    event.preventDefault();

    $.ajax({
      type: "POST",
      url: "/story_trackers/create",
      data: { story_id: event.params.storyId, user_id: event.params.userId },
      dataType: "json",
      success: (_xhr, status) => {
        if (status == "success") {
          this.toastOutlet.show("Added to story tracker");
        } else {
          this.toastOutlet.show("Sorry, we couldn't add to the story tracker. Please try again.", { success: false });
        }
      },
      error: () => {
        this.toastOutlet.show("Sorry, we couldn't add to the story tracker. Please try again.", { success: false });
      },
    });
  }
}
