import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clip-download"
export default class extends Controller {
  static values = {
    clipId: Number,
  };

  download() {
    $.ajax({
      type: "POST",
      url: `/clips/${this.clipIdValue}/downloaded`,
      dataType: "json",
    });
  }
}
