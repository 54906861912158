import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="resize-clip"
export default class extends Controller {
  static targets = ["clip"];

  expand() {
    $(this.clipTarget).addClass("expanded");
    this.updateTruncation();

    $(this.clipTarget).find("video").width("400");
    $(this.clipTarget).find("video").height("300");
  }

  minimize() {
    $(this.clipTarget).removeClass("expanded");
    this.updateTruncation();

    $(this.clipTarget).find("video").width("200");
    $(this.clipTarget).find("video").height("150");
  }

  updateTruncation() {
    $(this.clipTarget).find("h2").dotdotdot();
    $(this.clipTarget).find(".truncate").dotdotdot();
  }
}
